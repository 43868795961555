import {
	TextField,
	TextFieldProps
} from '@mui/material';
import React from 'react';

export type TextInputFieldProps = TextFieldProps & {
	value?: string | number;
	maxLength?: number;
	maxRows?: number;
}

const exceedsMaxLength = (value: string, length: number)=>!!(length && ( value?.toString().length ?? 0) > length);
const exceedsMaxRows = (value: string, rows?: number)=>!!rows && value?.split('\n').length >rows;

export const MultilineTextInputField: React.FC<TextInputFieldProps> = ( { maxLength = 1024, maxRows = undefined, value, ...props })=> {
	const strValue = value as string ?? props.defaultValue;
	const numMaxRows = Number(maxRows);
	return <TextField
		{ ...props }
		multiline={ true }
		// maxRows={ maxRows }
		//  || (strValue !== undefined && props.required && isEmpty(strValue)) //TODO: make required work logically
		error={ exceedsMaxLength(strValue, maxLength) || exceedsMaxRows(strValue, maxRows) }
		helperText={
			exceedsMaxLength(strValue, maxLength) ? (<span>Max text length exceeded.</span>) :
				strValue?.split('\n').length === numMaxRows ? (<span style={{ color: 'darkmagenta' }}>Max rows is {maxRows}.</span>) :
					strValue?.split('\n').length > numMaxRows ? (<span>Max rows exceeded.</span>) :
						strValue?.length === maxLength ? (<span style={{ color: 'darkmagenta' }}>Max text length is { maxLength }</span>) : props.helperText
		}
		inputProps={ { maxLength, ...props.inputProps } }
	/>;

};
